import React from "react";
import "../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {User} from "../types/Types";

interface Props {
    user: User | undefined
}

function UserNav(props: Props) {

    return (
        <Container>
            <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
                {props.user && <>
                    <NavLink className="nav-link" to={`/profile/${props.user.id}/visits`}>
                        Visits
                    </NavLink>
                    <NavLink className="nav-link" to={`/profile/${props.user.id}/sessions`}>
                        Check-ins
                    </NavLink>
                </>}
            </Nav>
        </Container>
    )
}

export default UserNav;
