import React, {useEffect} from "react";
import {Location, Session, User} from "../../types/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faHammer,
  faHotel, faLocationDot,
  faPencilAlt,
  faPlane,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {getGoogleMapsLink} from "../../util/Util";
import {collection, doc, getDocs, query, serverTimestamp, updateDoc, where} from "@firebase/firestore";
import {db} from "../../../firebase/FirebaseProvider";

interface Props {
  location: Location | undefined
  currentUser: User | undefined
}

function LocationDetails(props: Props) {

    useEffect(() => {
      (async () => {
        if(props.currentUser && props.currentUser.countUpdateEnabled) {
          await setCounts();
        }
      })();
    }, [props.currentUser, props.location]);

    const setCounts = async() => {
      if(!props.location) {
        return;
      }
      const visitsQuery = query(
          collection(db, 'visits'),
          where('locationId', '==', props.location.id),
          where('visited', "==", true)
      );
      const visitsSnapshot = await getDocs(visitsQuery);

      let sessions: Array<Session> = [];
      const sessionOnceCollection = query(
          collection(db, 'sessions'),
          where('locationId', '==', props.location.id)
      );
      const sessionsSnapshot = await getDocs(sessionOnceCollection);
      sessionsSnapshot.forEach((doc) => {
        let session = doc.data() as Session;
        sessions.push(session);
      });
      const uniqueSessions = new Set(sessions.map(session => session.locationId + "-" + session.userId));

      console.log(`Location name: ${props.location.name} - Values to update: [${visitsSnapshot.size}] visits - [${sessionsSnapshot.size}] sessions - [${uniqueSessions.size}] unique`)

      try {
        const locationRef = doc(db, 'locations', props.location.id);
        const data = {
          sessionCount: sessionsSnapshot.size,
          sessionUniqueCount: uniqueSessions.size,
          visitCount: visitsSnapshot.size,
        };
        await updateDoc(locationRef, data);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    return (
        <>
          {props.currentUser && <div className={"location-header"}>
                {props.location && <div>
                    <div>{props.location.name}</div>
                    <div style={{fontSize: "0.5em"}}>
                      {props.location.address}
                      <a className={"ml-1"}
                         style={{color: "white"}}
                         href={getGoogleMapsLink(props.location.name + ', ' + props.location.city)}
                         target={"_blank"}
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </a>
                    </div>
                    <div style={{fontSize: "0.5em"}}>
                        <div>
                            <span><b>{props.location.visitCount}</b> visits</span>
                            {(props.location && props.location.sessionUniqueCount > 0) &&
                            <div className={"d-inline-block ml-1"}>- <b>{props.location.sessionUniqueCount}</b> <FontAwesomeIcon icon={faLocationDot} /></div>}
                            {props.location.airport && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-plane" icon={faPlane} />
                            </small>}
                            {props.location.hotel && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-hotel" icon={faHotel} />
                            </small>}
                            {props.location.pubIsClosed && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-closed" icon={faTimes} />
                            </small>}
                            {(props.location.pubIsTemporaryClosed && !props.location.pubIsClosed) && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-temporary" icon={faHammer} />
                            </small>}
                            {props.currentUser.admin && <small className="text-truncate">
                                <Link to={`/admin/${props.location.id}`}>
                                    <FontAwesomeIcon className="ml-1 animated zoomIn icon-pen cursor-pointer" icon={faPencilAlt} />
                                </Link>
                            </small>}
                        </div>
                    </div>
                </div>}
                {!props.location && <div>...</div>}
            </div>}
        </>
    );
}

export default LocationDetails;