import React from "react";
import "../../nav/Navigation.css";
import {Link} from "react-router-dom";
import {Session, User, Visit} from "../../types/Types";
import VerificationTag from "../../tag/VerificationTag";
import AdminTag from "../../tag/AdminTag";
import {getDateFromTimeStamp, truncateString} from "../../util/Util";
import SupporterTag from "../../tag/SupporterTag";
import ChampionTag from "../../tag/ChampionTag";


interface Props {
    user: User | undefined
    visit: Visit | undefined
    session: Session | undefined
}


function UserTag(props: Props) {

    const isCheckedIn = (props.session && getDateFromTimeStamp(props.session.checkOutTime) > new Date()) || false;

    return (
        <>
            {props.user && <Link to={`/profile/${props.user.id}/visits`} className={"p-0 mr-1"}>
                <div className={`user-tag ${isCheckedIn ? "active-session" : ""}`}>
                    <div className={"d-inline-block"}>{truncateString(props.user.name)}</div>
                </div>
            </Link>}
            {props.user && !props.user.badgeDisabled && <>
                {props.user.admin && <div className={"d-inline-block"}>
                <AdminTag active={isCheckedIn} />
            </div>}
            {!props.user.admin && !props.user.badgeDisabled && props.user.supporter && <div className={"d-inline-block"}>
                <SupporterTag active={isCheckedIn} />
            </div>}
            {(!props.user.admin && !props.user.badgeDisabled && props.user.champion > 0) && <div className={"d-inline-block"}>
                <ChampionTag year={props.user.champion} active={isCheckedIn} />
            </div>}
            </>}
            {props.visit && props.visit.verified && <div className={"d-inline-block"}>
                <VerificationTag active={isCheckedIn}/>
            </div>}
        </>
    )
}

export default UserTag;
