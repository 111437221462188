import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheckCircle, faLocationDot, faPlane} from '@fortawesome/free-solid-svg-icons'
import "../../App.css";

interface Props {
    displaySessions: boolean;
    sessionCount: number;
    visitCount: number;
    visited: boolean;
    id: string,
    toggleVisited: (locationId: string) => void;
}

const getVisitedStyle = (visited: boolean) => {
    if(!visited) {
        return 'not-selected cursor-pointer font-1dot5em';
    } else {
        return 'white cursor-pointer font-1dot5em';
    }
}

function VisitElement(props: Props) {

  function getValueToDisplay(): number {
    if(props.displaySessions) {
      return props.sessionCount
    } else {
      return props.visitCount
    }
  }

    return (
        <div>
            <div className={"text-center"}>
                 <FontAwesomeIcon className={getVisitedStyle(props.visited)}
                                  onClick={() => props.toggleVisited(props.id)}
                                  icon={faCheckCircle} />
            </div>
          <div className={"text-center"}>
            <div className={"d-inline-block"}>{getValueToDisplay()}</div>
            {props.displaySessions && <div className={"text-center d-inline-block"}>
                <small>
                    <FontAwesomeIcon className="ml-1" icon={faLocationDot} />
                </small>
              </div>}
          </div>
        </div>
    )
}

export default VisitElement;