import React, {Component, useEffect, useState} from "react";
import {Session, User} from "../types/Types";
import {Card} from "react-bootstrap";
import UserRow from "./UserRow";
import LeaderboardNav from "./LeaderboardNav";
import {addNumberSuffix} from "../util/Util";

interface Props {
    users: Array<User>;
    currentUser: User | undefined;
    session: Session | undefined;
}

function LeaderboardVisits(props: Props) {

    const [rank, setRank] = useState<number>(0);

    useEffect(() => {
        (async () => {
            props.users.map((user, index) => {
                if(props.currentUser && user.id === props.currentUser.id) {
                    setRank(index - 1);
                    return;
                }
            });
        })();
    }, [props.users, props.currentUser]);

    return (
        <>
        {props.currentUser && <>
            <LeaderboardNav currentUser={props.currentUser} />
            {props.users && <Card className={"mb-3"}>
                <Card.Header>
                    {(props.users.length == 0 || rank === 0) && <div>Calculating...</div>}
                    {(props.users.length > 0 && rank > 0) && <div>You're ranked: {rank}<small>{addNumberSuffix(rank)}</small> out of {props.users.length}</div>}
                </Card.Header>
                <Card.Body style={{paddingTop: "0.4em"}}>
                    {props.users
                        .filter((user) => {return !user.socialDisabled})
                        .filter((user) => {return user.visitCount > 0})
                        .map((user: User, index: number) => (
                        <UserRow countToDisplay={user.visitCount.toString()} key={user.id} index={index} user={user} session={props.session}/>
                    ))}
                    {(props.users.length == 0 || rank === 0) && <div className={'loading'}>
                        Loading...
                    </div>}
                </Card.Body>
            </Card>}
        </>}
        </>
    );
}

export default LeaderboardVisits;