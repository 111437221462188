import {GoogleMap, useJsApiLoader, Marker, GoogleMapProps, InfoWindow} from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import {User, Location, Visit, Session} from "../types/Types";
import {collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where} from "@firebase/firestore";
import {db, functions} from "../../firebase/FirebaseProvider";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {httpsCallable} from "@firebase/functions";
import history from "../util/History";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getGoogleMapsLink} from "../util/Util";

const containerStyle = {
    width: "100%",
    height: "67vh"
};

const center = {
    lat: 54.8,
    lng: -3.0
};

interface Props {
    locations: Array<Location>;
    sessions: Array<Session>;
}

function MapElement(props: Props) {

    const [locationsMap, setLocationsMap] = useState<Map<string, Location> | undefined>(undefined);
    const [visitsMap, setVisitsMap] = useState<Map<string, Visit> | undefined>(undefined);
    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
    const [popupIndex, setPopupIndex] = useState<number | undefined>(undefined);
    const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const auth = getAuth();
            onAuthStateChanged(auth, async (currentUser) => {
                if (!currentUser) {
                    setCurrentUser(undefined);
                    history.push('/');
                    return;
                }
                loadData(currentUser.uid);
            });
        })();
    }, [props.locations]);

    const loadData = async(currentUserId: string) => {
        const userCollection = query(collection(db, 'users'), where('authId', '==', currentUserId));
        const userCollectionSnap = await getDocs(userCollection);
        userCollectionSnap.forEach(async (userDoc) => {
            let userToSet: User = userDoc.data() as User;
            userToSet.id = userDoc.id;
            setCurrentUser(userToSet);
            let res = await fetchLocations(userToSet);
            if (res.success && res.data) {
                setLocationsMap(res.data.locationsMap);
                setVisitsMap(res.data.visitsMap);
            }
            return;
        });
    }

    const fetchLocations = async (currentUser: User) => {
        try {
            const visitsCollection = query(collection(db, 'visits'), where('userId', '==', currentUser.id));
            const visitsCollectionSnapshot = await getDocs(visitsCollection);
            const visitsMap: Map<string, Visit> = new Map<string, Visit>();
            visitsCollectionSnapshot.forEach((doc) => {
                visitsMap.set(doc.data().locationId, doc.data() as Visit);
            });

            let locationsMap: Map<string, Location> = new Map<string, Location>();
            props.locations.forEach((location) => {
                const visit = visitsMap.get(location.id);
                if(!location.pubIsClosed ||(location.pubIsClosed && (visit && visit.visited))) {
                    locationsMap.set(location.id, location);
                }
            });
            return { success: true, data: { locationsMap, visitsMap } };
        } catch (error) {
            console.log(error);
            return { success: false };
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCY-IMUNJ5d-1o5KUMhCUBVBnM_Yfklu5E"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        //const bounds = new window.google.maps.LatLngBounds(center);
        //map.fitBounds(bounds);
        //setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const getIconColor = (location: Location) => {
        if(location.visited && getLocationSessions(location.id).length) {
            return "/images/visited-marker-active2.png";
        } else if(location.visited) {
            return "/images/visited-marker.png";
        } else if(!location.visited && getLocationSessions(location.id).length) {
            return "/images/new-marker-active2.png";
        } else {
            return "/images/new-marker.png";
        }
    }

    const getZIndex = (location: Location) => {
        if(location.visited && getLocationSessions(location.id).length) {
            return 4;
        } else if(!location.visited && getLocationSessions(location.id).length) {
            return 3;
        } else if(location.visited) {
            return 2;
        }
        return 1;
    }

    // Could potentially save the users map location, not sure there's much point
    // const onZoomChanged = () => {
    //     this.usersRef.doc(this.state.users.uid).update({
    //         mapUserZoom: this.googleMap.current.getZoom()
    //     });
    // };
    //
    // const onCentreChanged = () => {
    //     this.usersRef.doc(this.state.users.uid).update({
    //         mapUserCentreLat: this.googleMap.current.getCenter().lat(),
    //         mapUserCentreLon: this.googleMap.current.getCenter().lng()
    //     });
    // };

    const processLocations = (locations: Array<Location>, visitsMap: Map<string, Visit>): Array<Location> => {
        locations.map((location) => {

            // Set if location is visited
            if(currentUser && visitsMap) {
                let visitCheck = visitsMap.get(location.id);
                if(visitCheck && visitCheck.visited) {
                    location.visited = true;
                } else {
                    location.visited = false;
                }
            }
        });

        return locations.filter(location => ((location.visited || !location.pubIsClosed)));
    }

    const divStyle = {
        background: `white`,
        border: `1px solid #ccc`,
        padding: 15
    }

    const addVisit = (location: Location): void => {
        if(!currentUser) {
            return;
        }
        var toggleVisitedFunction = httpsCallable(functions, 'toggleVisitedV2');
        toggleVisitedFunction({locationId: location.id}).then(r => {
            loadData(currentUser.authId);
        });
    }

    const displayVisitedButton = (location: Location) => {
        if (visitsMap && visitsMap.has(location.id) && visitsMap.get(location.id)?.visited) {
            return false;
        }
        return true;
    };

    const getLocationSessions = (locationId: string): Array<Session> => {
        let locationSessions = new Array();
        props.sessions.map(session => {
           if(session.locationId === locationId) {
               locationSessions.push(session);
           }
        });
        return locationSessions
    }

    return isLoaded ? (
        <>
            {props.locations && props.locations.length > 0 && <div style={{width: "100%", height: "80%"}}><GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={5.4}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{streetViewControl: false}}
            >
                <>
                    {props.locations && props.locations.length > 0  && visitsMap && processLocations(props.locations, visitsMap).map((location: Location, index) => (
                        <Marker
                            key={location.id}
                            icon={getIconColor(location)}
                            zIndex={getZIndex(location)}
                            position={{ lat: +location.lat, lng: +location.lng }}
                            onClick={() => { setPopupIndex(index); setIsOpen(true) }}
                            title={location.name}
                        >
                            {isOpen && popupIndex === index && (
                                <InfoWindow>
                                    <div className="info-window">
                                        <div className={"info-header"}><Link to={`/location/${location.id}/comments`}>{location.name}</Link></div>
                                        <div className={"info-address"}><Link to={{pathname:`${getGoogleMapsLink(location.name + ', ' + location.city)}`}} target="_blank" rel="noopener noreferrer">{location.address}</Link></div>
                                        <div className={"info-address"}>
                                            <div className={"mt-1"}>
                                                Visit count: <strong>{location.visitCount}</strong>
                                            </div>
                                        </div>
                                        {getLocationSessions(location.id).length > 0 && <div className={"mt-1"}><span>Users currently checked in: <b>{getLocationSessions(location.id).length}</b></span></div>}
                                        {getLocationSessions(location.id).length > 0 && <div className={"mt-1"}>
                                            {getLocationSessions(location.id).map((session) => (
                                                <div><Link to={`/profile/${session.userId}/visits`}>{session.name}</Link></div>
                                            ))}
                                        </div>}
                                        <div
                                            className={
                                                displayVisitedButton(location)
                                                    ? "record-visit d-flex justify-content-end"
                                                    : "record-visit d-none"
                                            }
                                        >
                                            <Button size="sm" onClick={() => { addVisit(location) }}>
                                                Record Visit
                                            </Button>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>)
                    )}
                </>
            </GoogleMap></div>}
        </>
    ) : <></>
}

export default React.memo(MapElement)