import React from "react";
import "../nav/Navigation.css";
import {NavLink} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {User} from "../types/Types";
import history from "../util/History";

interface Props {
  currentUser: User | undefined
}

class MessagesNav extends React.PureComponent<Props, {}> {
  public render() {
    return (
        <Container>
          <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
            <>
              <NavLink className="nav-link" to="/chat/general" onClick={() => this.navigateToChannel("general")}>
                General
              </NavLink>
              <NavLink className="nav-link" to="/chat/support" onClick={() => this.navigateToChannel("support")}>
                Support
              </NavLink>
            </>
          </Nav>
        </Container>
    );
  }

  private navigateToChannel = (channel: string) => {
    //history.push("/chat/" + channel);
    //window.location.reload();
  }
}

export default MessagesNav;
