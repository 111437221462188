import React, {Component, useEffect, useRef} from "react";
import {Card} from "react-bootstrap";
import {User} from "../types/Types";
import {Link} from "react-router-dom";
import FaSymbol from "../home/components/FaSymbol";
import {
    faArrowUpRightFromSquare,
    faBeer, faBeerMugEmpty,
    faCheckCircle, faCog, faCoins,
    faHammer,
    faHotel,
    faLocationDot, faMessage,
    faPlane, faScrewdriverWrench, faSignOutAlt,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import ImageSymbol from "../home/components/ImageSymbol";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
    currentUser: User | undefined;
}

function Support(props: Props) {

    const pointsHelpRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (pointsHelpRef && window.location.hash.includes("#points-help")) {
            pointsHelpRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "center",
            });
        }
    }, [pointsHelpRef, window.location.hash]);

    return (
        <>
            <Card>
                <Card.Header>
                    <div><b>Support</b></div>
                </Card.Header>
                <Card.Body>
                    {!props.currentUser &&
                    <p>If you are having trouble creating an account or logging in, please email <a href="mailto:spoonstracker.com@gmail.com">spoonstracker.com@gmail.com</a>.</p>}
                    {props.currentUser &&
                    <p>If you need to raise an issue or provide feedback, head to the chat page and click on the support channel. If unavailable, please email <a href="mailto:spoonstracker.com@gmail.com">spoonstracker.com@gmail.com</a>.</p>}
                    <p>If you would like to donate to the developers it would be greatly appreciated, £5 is the rough monthly cost of running the site. You'll also receive a digital supporters badge.</p>
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="SMDZ7R6VLCX3Q" />
                                    <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif"
                                           name="submit" alt="PayPal – The safer, easier way to pay online!" />
                                    <img alt="" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </div>
                </Card.Body>
            </Card>
            {props.currentUser && <><Card className={"mt-3"}>
                <Card.Header>
                    <div><b>How to use this site</b></div>
                </Card.Header>
                <Card.Body>
                    <ul className={"bullet-points"}>
                        <li><div className={"mb-2"}>Please navigate to the <Link to={"/pubs"}>Pubs</Link> page to start marking off your visits.</div></li>
                        <li><div className={"mb-2"}>Click on the <Link to={"/location/a1f44f247-298d-4303-b4ea-f373ae2ecacf/comments"}>pub name</Link> to get more information, add comments, check in and to see who else has visited.</div></li>
                        <li><div className={"mb-2"}>The <Link to={"/hub/visits"}>Hub</Link> will provide a news feed of whats happening at each location.</div></li>
                        <li><div className={"mb-2"}>Use the <Link to={"/map"}>Map</Link> to get clear view of where you have visited.</div></li>
                        <li><div className={"mb-2"}>Get a list of pubs you've visited from your {props.currentUser && <Link to={`/profile/${props.currentUser.id}/visits`}>user profile</Link>}{!props.currentUser && <span>user profile</span>}.</div></li>
                        <li><div className={"mb-2"}>See how you size up against other users on the <Link to={"/leaderboard"}>Users</Link> page.</div></li>
                        <li><div className={"mb-2"}>Visit the <Link to={"/settings"}>settings</Link> page to configure your account and set a password.</div></li>
                        <li><div className={"mb-2"}>Use the filter and sort options to decide where to visit next!</div></li>
                    </ul>
                </Card.Body>
            </Card>
                <Card className={"mt-3 mb-3"}>
                    <Card.Header>
                        <div ref={pointsHelpRef}><b>'Spoons Tracker points</b><FontAwesomeIcon className={"ml-2"} icon={faCoins} /></div>
                    </Card.Header>
                    <Card.Body>
                        <ul className={"bullet-points"}>
                            <li>
                                <div className={"mb-2"}>
                                    Earn <b>10 points</b> the first time you check into a new 'Spoons.
                                </div>
                            </li>
                            <li>
                                <div className={"mb-2"}>
                                    Earn <b>5 points</b> for each subsequent check-in. If you check out early then you earn a proportion of the points, for example 40 minutes is 3 points.
                                </div>
                            </li>
                            <li>
                                <div className={"mb-2"}>
                                    Earn <b>100 points</b> for checking into the current bonus pub. A new bonus pub is selected every 1st of the month.
                                </div>
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
            <Card className={"mt-3 mb-3"}>
                <Card.Header>
                    <div><b>Symbols and their meanings</b></div>
                </Card.Header>
                <Card.Body>
                    <div>
                        <div className={"d-inline-block mr-2"}><b>User badges:</b></div>
                        <FaSymbol class={"white"} faIcon={faScrewdriverWrench} description={"Site admin"}/>
                        <FaSymbol class={"white"} faIcon={faBeerMugEmpty} description={"'Spoons Tracker supporter"}/>
                    </div>
                    <div className={"mt-2"}>
                        <div className={"d-inline-block mr-2"}><b>Pub status:</b></div>
                        <FaSymbol class={"icon-open"} faIcon={faBeer} description={"Pub is open"}/>
                        <FaSymbol class={"icon-temporary"} faIcon={faHammer} description={"Pub is renovating"}/>
                        <FaSymbol class={"icon-closed"} faIcon={faTimes} description={"Pub is closed"}/>
                        <FaSymbol class={"icon-plane"} faIcon={faPlane} description={"Pub is in an airport"}/>
                        <FaSymbol class={"icon-hotel"} faIcon={faHotel} description={"Pub is a hotel"}/>
                    </div>
                    <div className={"mt-2"}>
                        <div className={"d-inline-block mr-2"}><b>Pub visits:</b></div>
                        <FaSymbol class={"not-selected"} faIcon={faCheckCircle} description={"Not yet visited pub"}/>
                        <FaSymbol class={"white"} faIcon={faCheckCircle} description={"Visited pub"}/>
                        <FaSymbol class={"white"} faIcon={faLocationDot} description={"Verified visit"}/>
                    </div>
                    <div className={"mt-2"}>
                        <div className={"d-inline-block mr-2"}><b>Map:</b></div>
                        <ImageSymbol class={"white"} image={"/images/visited-marker.png"} description={"Visited pub"}/>
                        <ImageSymbol class={"white"} image={"/images/new-marker.png"} description={"Not visited pub"}/>
                        <ImageSymbol class={"white"} image={"/images/new-marker-active2.png"} description={"Active check-ins"}/>
                    </div>
                    <div className={"mt-2"}>
                        <div className={"d-inline-block mr-2"}><b>Other:</b></div>
                        <FaSymbol class={"not-visited"} faIcon={faMessage} description={"No new messages"} />
                        <FaSymbol class={"white"} faIcon={faMessage} description={"New messages"} />
                        <FaSymbol class={"not-visited"} faIcon={faCog} description={"Settings"} />
                        <FaSymbol class={"not-visited"} faIcon={faSignOutAlt} description={"Log out"} />
                        <FaSymbol class={"white"} faIcon={faCoins} description={"'Spoons Tracker points"} />
                    </div>
                </Card.Body>
            </Card>
            </>}
            {!props.currentUser && <>
            <Card className={"mt-3"}>
                <Card.Header>
                    <div><b>Delete my account & GDPR concerns</b></div>
                </Card.Header>
                <Card.Body>
                    <p>GDPR - If you would like to delete your data, please email <a href="mailto:spoonstracker.com@gmail.com">here</a> with a subject of "Delete".</p>
                </Card.Body>
            </Card>
            <Card className={"mt-3 mb-3"}>
                <Card.Header>
                    <div><b>Privacy policy</b></div>
                </Card.Header>
                <Card.Body>
                    <p>For our privacy policy please click <a href="https://www.termsfeed.com/live/2e457757-1243-4bef-87c4-894b0d25d664">here</a>.</p>
                </Card.Body>
            </Card>
            </>}
        </>
    )
}

export default Support;