import {Location, NewsItem, Session, User} from "../types/Types";
import React from "react";
import {Link} from "react-router-dom";
import {addAnchorTags, getDate, getDateFromTimeStamp, getTime} from "../util/Util";
import VerificationTag from "../tag/VerificationTag";
import UserTag from "../users/tag/UserTag";

interface Props {
    session: Session;
    user: User | undefined;
    displayName: boolean;
    displayLocation: boolean;
}

function SessionRow(props: Props) {

    const isCheckedIn = props.session && getDateFromTimeStamp(props.session.checkOutTime) > new Date() || false;

    return (
        <>
            <div className={`news-item`}>

                <div>
                    {props.displayName && <>
                        <div className={`d-inline-block`}>
                            {props.user && <Link to={`/profile/${props.user.id}/visits`} className={"p-0"}>
                                <UserTag user={props.user} visit={undefined} session={props.session}/>
                            </Link>}
                        </div>
                    </>}
                    {(props.displayName && props.displayLocation) && <>
                        <div className={"d-inline-block mr-1"}>
                            {!isCheckedIn && <span>checked into</span>}
                            {isCheckedIn && <span>is checked into</span>}
                        </div>
                    </>}
                    {props.displayLocation && <>
                        <div className={`d-inline-block mr-1`}>
                            <Link to={`/location/${props.session.locationId}/comments`} className={"p-0"}>
                                <div className={`location-tag ${isCheckedIn ? "active-session" : ""}`}>{props.session.locationName}</div>
                            </Link>
                        </div>
                    </>}
                    <VerificationTag active={isCheckedIn} />
                </div>

                <div className={"mt-2"}>
                    <div style={{float: "right"}}>
                        <small className={"ml-2 d-inline-block"}>
                            <div className={"d-inline-block"}>{getDate(props.session.checkInTime)} at {getTime(props.session.checkInTime)}</div>
                        </small>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SessionRow;