import React, {Component, useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {getAuth, onAuthStateChanged} from "@firebase/auth";
import history from "../util/History";
import {Location, NewsItem, Session, User} from "../types/Types";
import {collection, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";
import NewsItemRow from "./NewsItemRow";
import HubNav from "./HubNav";
import {NOTIFICATION_LIMIT} from "../util/Util";

interface Props {
    usersMap: Map<string, User>;
    session: Session | undefined;
}

function Visits(props: Props) {


    const [newsItems, setNewsItems] = useState<Array<NewsItem> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const auth = getAuth();
            onAuthStateChanged(auth, async (currentUser) => {
                if (!currentUser) {
                    history.push('/');
                    return;
                }
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadAllData();
        })();
    }, []);

    const loadAllData = async() => {

        // Load visit news items
        let newsItems: Array<NewsItem> = [];
        const newsOnceCollection = query(collection(db, 'news'),
            where('type', '==', 'VISIT'),
            orderBy('lastModified', 'desc'),
            limit(NOTIFICATION_LIMIT));
        const newsOnceCollectionSnapshot = await getDocs(newsOnceCollection);
        newsOnceCollectionSnapshot.forEach((doc) => {
            let tempNewsItem = doc.data() as NewsItem;
            newsItems.push(tempNewsItem);
        });
        setNewsItems(newsItems);

    }

    return (
        <>
            <HubNav />
            <Card className={"mb-3"}>
                <ListGroup variant="flush">
                    {!newsItems && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(newsItems && newsItems.length == 0) &&<div className={'loading'}>
                        No visits yet
                    </div>}
                    {newsItems && newsItems.filter(newsItem => {
                        const user = props.usersMap.get(newsItem.userId);
                        return user && !user.socialDisabled
                    }).map((newsItem: NewsItem, index: number) => (
                        <NewsItemRow user={props.usersMap.get(newsItem.userId)} newsItem={newsItem} session={props.session}/>
                    ))}
                </ListGroup>
            </Card>
        </>
    )
}

export default Visits;