import React, {Component, useEffect, useRef, useState} from "react";
import {db} from '../../firebase/FirebaseProvider'
import {
    doc,
    collection,
    getDoc,
    getDocs,
    Timestamp,
    query,
    orderBy,
    onSnapshot,
    where,
    limit,
    setDoc
} from 'firebase/firestore'
import {Location, Session, User, Visit} from "../types/Types";
import {Card, ListGroup} from "react-bootstrap";
import LocationRow from "./LocationRow";
import UserDetails from "./UserDetails";
import UserNav from "./UserNav";
import {NOTIFICATION_LIMIT} from "../util/Util";
import {Link} from "react-router-dom";
import VerificationTag from "../tag/VerificationTag";
import SessionRow from "../shared/SessionRow";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    locations: Array<Location> | undefined
}

function UserSessions(props: Props) {

    const [sessions, setSessions] = useState<Array<Session> | undefined>(undefined);
    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const userRef = doc(db, 'users', props.match.params.id);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                let userToSet = docSnap.data() as User;
                userToSet.id = docSnap.id;
                setUser(userToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadAllData();
        })();
    }, [user]);

    const loadAllData = async() => {
        if(!user) {
            return;
        }
        // Load user sessions
        let sessions: Array<Session> = [];
        const sessionOnceCollection = query(collection(db, 'sessions'),
            where('userId', '==', user.id),
            orderBy('checkInTime', 'desc'),
            limit(NOTIFICATION_LIMIT));
        const sessionOnceCollectionSnapshot = await getDocs(sessionOnceCollection);
        sessionOnceCollectionSnapshot.forEach((doc) => {
            let session = doc.data() as Session;
            sessions.push(session);
        });
        setSessions(sessions);
    }

    return (
        <>
            <UserDetails user={user}/>
            <UserNav user={user}/>
            <Card className={"mb-3"}>
                <ListGroup variant="flush">
                    {sessions && sessions.map((session: Session, index: number) => (
                        <SessionRow displayName={false} displayLocation={true} session={session} user={user}/>
                    ))}
                    {!sessions && <>
                        <div className={"loading"}>Loading...</div>
                    </>}
                    {(sessions && sessions.length == 0) &&<div className={'loading'}>
                        No check-ins yet
                    </div>}
                </ListGroup>
            </Card>
        </>
    );
}

export default UserSessions;