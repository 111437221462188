import {Location, NewsItem, Session, User} from "../types/Types";
import React from "react";
import {Link} from "react-router-dom";
import {addAnchorTags, getDate, getTime} from "../util/Util";
import VerificationTag from "../tag/VerificationTag";
import UserTag from "../users/tag/UserTag";

interface Props {
    newsItem: NewsItem;
    user: User | undefined;
    session: Session | undefined;
}

function NewsItemRow(props: Props) {

    const getNewsContent = (newsItem: NewsItem): string => {
        let news = "";

        if(newsItem.type === 'VISIT') {
            news = " ticked ";
        } if(newsItem.type === 'VISIT' && newsItem.verified) {
            news = " visited ";
        } else if(newsItem.type === 'LOCATION' && newsItem.subType === 'UPDATE') {
            news = "\u00A0updated ";
        } else if(newsItem.type === 'LOCATION' && newsItem.subType === 'CREATION') {
            news = " added ";
        } else if(newsItem.type === 'COMMENT') {
            news = " commented on "
        }

        return news;
    }

    return (
        <>
            {<div className={"news-item"}>

                <div>
                    <div className={"d-inline-block"}>
                        {props.user && <Link to={`/profile/${props.user.id}/visits`} className={"p-0"}>
                            <UserTag user={props.user} visit={undefined} session={undefined}/>
                        </Link>}
                    </div>
                    <div className={"d-inline-block mr-1"}>
                        {getNewsContent(props.newsItem)}
                    </div>
                    <div className={"d-inline-block"}>
                        <Link to={`/location/${props.newsItem.locationId}/comments`} className={"p-0"}>
                            <div className={"location-tag"}>{props.newsItem.locationName}</div>
                        </Link>
                    </div>
                    {props.newsItem && props.newsItem.verified && <div className={"d-inline-block"}>
                        <VerificationTag active={false}/>
                    </div>}
                    {props.newsItem.type === 'COMMENT' && <span className={"mr-1 ml-1"}>
                        "<span dangerouslySetInnerHTML={{ __html: addAnchorTags(props.newsItem.payload)}} />"
                    </span>}
                </div>

                <div className={"mt-2"} >
                    {props.newsItem.type === 'VISIT' &&
                    <div style={{float: "left"}}>
                        <small>
                            <>
                                {(Number(props.newsItem.payload).toString() !== 'NaN' &&
                                    Number(props.newsItem.payload).toString() !== '0')
                                && <div className={"d-inline-block mr-1"}>
                                    Visit number {Number(props.newsItem.payload).toString()}
                                </div>}
                                {(Number(props.newsItem.payload).toString() === 'NaN' &&
                                    Number(props.newsItem.payload).toString() !== '0')
                                && <div className={"d-inline-block mr-2"}>
                                    Visit number 1
                                </div>}
                            </>
                        </small>
                    </div>}
                    <div style={{float: "right"}}>
                        <small className={"ml-2 d-inline-block"}>
                            <div className={"d-inline-block"}>{getDate(props.newsItem.lastModified)} at {getTime(props.newsItem.lastModified)}</div>
                        </small>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default NewsItemRow;