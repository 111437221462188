import React, {Component} from "react";
import {Session, User} from "../types/Types";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import UserTag from "../users/tag/UserTag";

interface Props {
    index: number;
    user: User;
    countToDisplay: string;
    session: Session | undefined;
}

function UserRow(props: Props) {
    return (
        <div>
            <Row className="align-middle flex-nowrap leaderboard-row">
                <Col className="col-auto d-flex" style={{paddingRight: "0px"}}>
                    <div style={{minWidth: "50px"}}>
                        <div style={{textAlign: "center", paddingBottom: "0.4em"}}>
                            {props.index === 0 && <div className={"userIcon gold-pos"}>{props.index + 1}</div>}
                            {props.index === 1 && <div className={"userIcon silver-pos"}>{props.index + 1}</div>}
                            {props.index === 2 && <div className={"userIcon bronze-pos"}>{props.index + 1}</div>}
                            {props.index > 2 && <div className={"userIcon"}>{props.index + 1}</div>}
                        </div>
                    </div>
                </Col>
                <Col className="col-auto d-flex pt-2" style={{minWidth: "215px"}}>
                    <div>
                        <Link
                            to={`/profile/${props.user.id}/visits`}
                            className="nav-link p-0"
                        >
                            <UserTag user={props.user} session={props.session} visit={undefined} />
                        </Link>
                    </div>
                </Col>
                <Col className="col-auto d-flex pt-2">
                    {props.countToDisplay.length > 4 && <small>
                        {props.countToDisplay}
                    </small>}
                    {props.countToDisplay.length <= 4 && <div>
                        {props.countToDisplay}
                    </div>}
                </Col>
            </Row>
        </div>
    )
}

export default UserRow;