import React from "react";
import "../nav/Navigation.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScrewdriverWrench, faTrophy} from "@fortawesome/free-solid-svg-icons";

interface Props {
  active: boolean;
  year: number;
}

function ChampionTag(props: Props) {

    return (
        <>
            <div className={`user-tag d-inline-block ${props.active ? "active-session" : ""}`}>
                <small><FontAwesomeIcon icon={faTrophy}/> '{props.year.toString().slice(-2)}</small>
            </div>
        </>
    )
}

export default ChampionTag;
