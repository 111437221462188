import React from "react";
import "../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {faClock, faCoins, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Location, User, Visit} from "../types/Types";

interface Props {
    currentUser: User | undefined;
}

function LeaderboardNav(props: Props) {
    return (
        <Container>
            <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
                <>
                    <NavLink className="nav-link" to="/leaderboard/visits">
                        Visits
                    </NavLink>
                    <NavLink className="nav-link" to="/leaderboard/verified">
                        Verified <small><FontAwesomeIcon icon={faLocationDot} /></small>
                    </NavLink>
                    <NavLink className="nav-link" to="/leaderboard/sessions">
                        Check-in <small><FontAwesomeIcon icon={faClock}/></small>
                    </NavLink>
                    {props.currentUser && !props.currentUser.socialDisabled && <NavLink className="nav-link" to="/leaderboard/year">
                        Year <small><FontAwesomeIcon icon={faCoins}/></small>
                    </NavLink>}
                </>
            </Nav>
        </Container>
    )
}

export default LeaderboardNav;
