import React from "react";
import { User } from "../types/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faCoins, faBeerMugEmpty, faScrewdriverWrench, faTrophy} from "@fortawesome/free-solid-svg-icons";
import {calculateAndFormatPoints} from "../util/Util";

interface Props {
    user: User | undefined;
}

function UserDetails(props: Props) {
    return (
        <>
            {props.user && <div className={"user-header"}>
                {props.user && <div>{props.user.name}</div>}
                <div style={{marginTop: "-0.4em"}}>
                    {!props.user && <div>...</div>}
                        {(props.user && props.user.admin) &&  <div className={"mt-2"} style={{marginBottom: "-10px", fontSize: "0.5em"}}>'Spoons Tracker Admin <FontAwesomeIcon icon={faScrewdriverWrench} /></div>}
                        {(props.user && props.user.supporter) &&  <div className={"mt-2"} style={{marginBottom: "-10px", fontSize: "0.5em"}}>'Spoons Tracker Supporter <FontAwesomeIcon icon={faBeerMugEmpty} /></div>}
                        {(props.user && props.user.champion > 0) &&  <div className={"mt-2"} style={{marginBottom: "-10px", fontSize: "0.5em"}}>'Spoons Tracker Champion {props.user.champion} <FontAwesomeIcon icon={faTrophy} /></div>}
                    <div>
                        {(props.user && props.user.visitCount > 0) && <div className={"d-inline-block"} style={{fontSize: "0.5em"}}><b>{props.user.visitCount}</b> visits</div>}
                        {(props.user && !props.user.visitCount) && <div className={"d-inline-block"} style={{fontSize: "0.5em"}}><b>0</b> visits</div>}
                        {(props.user && props.user.sessionVerifiedCount > 0) &&
                            <>
                                <div className={"d-inline-block ml-1"} style={{fontSize: "0.5em"}}>- <b>{props.user.sessionVerifiedCount}</b> <FontAwesomeIcon icon={faLocationDot} /></div>
                                <div className={"d-inline-block ml-1"} style={{fontSize: "0.5em"}}>- <b>{calculateAndFormatPoints(props.user)}</b> <FontAwesomeIcon icon={faCoins} /></div>
                            </>
                        }
                    </div>
                </div>
            </div>}
        </>
    );
}

export default UserDetails;